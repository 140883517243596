import type { FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import InfoItem from '@components/mainPage/mainBlock/searchTrains/info/InfoItem'
import { InfoWrapper } from '@components/mainPage/mainBlock/searchTrains/info/styles'

const Info: FC = () => {
  const { t } = useTranslation('Search form')

  return (
    <InfoWrapper>
      <InfoItem description={t('supportDescription')} title={t('supportTitle')} />
      <InfoItem description={t('paymentDescription')} title={t('paymentTitle')} />
      <InfoItem description={t('timetableForDescription')} title={t('timetableForTitle')} />
    </InfoWrapper>
  )
}

export default memo(Info)
