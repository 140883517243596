import type { FC } from 'react'
import styled from 'styled-components'

import type { LayoversListType } from '@redux/features/recommendedRoutes/types/layover'
import { selectWithLayover } from '@redux/features/search/search.selectors'
import { useAppSelector } from '@redux/hooks'

import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import {
  formHeightDesktop,
  searchFormSizes,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'
import type { PropsLayovers } from '@components/mainPage/recommendedRoutes/components/recommendedLayovers/RecommendedLayovers'
import Header from '@components/mainPage/recommendedRoutes/components/recommendedLayovers/components/header/Header'
import LayoverList from '@components/mainPage/recommendedRoutes/components/recommendedLayovers/components/layoverList/LayoverList'
import { ContainerStyled } from '@components/mainPage/recommendedRoutes/styles/commonStyles'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'
import MobileNavBar, { MobileNavBarWrapper } from '@widgets/checkout/timetable/components/mobileNavBar/MobileNavBar'

const LayoversContent: FC<PropsLayovers & { layoversList: LayoversListType }> = ({
  arrivalStation,
  departureDate,
  departureStation,
  layoversList,
  selectedLayover,
  setStation,
  submitForm,
  widthPopup,
}) => {
  const isMobile = useMediaQuery(mediaQueries.mobile)
  const withLayover = useAppSelector(selectWithLayover)

  const handlerSearchInFooter = () => {
    setStation(SearchFormKeys.arrival, undefined)
    setStation(SearchFormKeys.layover, undefined)
  }

  return (
    <Container $isLayover={withLayover} width={widthPopup}>
      <Header arrivalStation={arrivalStation} departureDate={departureDate} departureStation={departureStation} />
      <LayoverList
        list={layoversList}
        selectedLayover={selectedLayover}
        setStation={setStation}
        submitForm={submitForm}
      />
      {isMobile && <MobileNavBar handlerSearch={handlerSearchInFooter} />}
    </Container>
  )
}

const Container = styled.div<{ $isLayover: boolean; width: number }>`
  width: ${({ width }) => width}px;
  min-height: ${p => formHeightDesktop + (p.$isLayover ? searchFormSizes.layoversHeightDesktop : 0)}px;

  ${ContainerStyled}

  ${p => p.theme.mediaQueries.desktop} {
    height: auto;
  }

  ${p => p.theme.mediaQueries.mobile} {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    border-radius: 0;
    padding: 0;
    margin: 0;

    ${MobileNavBarWrapper} {
      box-shadow: 0 0 60px rgba(0, 0, 0, 0.05);
    }
  }
`

export default LayoversContent
