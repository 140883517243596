import Form from 'antd/es/form'
import { useRouter } from 'next/router'
import type { FC } from 'react'
import { memo, useCallback, useEffect } from 'react'

import { fixedPostSearchCacheKey, usePostSearchMutation } from '@redux/features/checkout/api'
import { setQueryParams } from '@redux/features/checkout/checkout.slice'
import { selectIsOpenLayovers } from '@redux/features/recommendedRoutes/recommendedRoutes.selectors'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

import { headerId } from '@components/header/Header'
import DateInput from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput'
import { popupPositionsMainPage } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput/constants/positions'
import {
  SearchFormKeys,
  searchFormId,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import PassengersLabel from '@components/mainPage/mainBlock/searchTrains/search/searchForm/passengers/Passengers'
import StationsFields from '@components/mainPage/mainBlock/searchTrains/search/searchForm/stationsFields/StationsFields'
import SubmitButton from '@components/mainPage/mainBlock/searchTrains/search/searchForm/submitButton/SubmitButton'
import { onErrorHandler } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/utils/errorHendler'
import type { SearchFormValuesType } from '@components/mainPage/mainBlock/searchTrains/search/types/formTypes'
import RecommendedRoutes from '@components/mainPage/recommendedRoutes/RecommendedRoutes'
import ShadowMask from '@components/mask/ShadowMask'
import routes from '@constants/routes/routes'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import useTrainSearchForm from '@hooks/useTrainSearch/useTrainSearchForm'
import { mediaQueries } from '@themes/mediaQueries'

const SearchForm: FC = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const {
    adults,
    arrivalStationValue,
    children,
    childrenAge,
    closePopup,
    date,
    departureStationValue,
    form,
    initialValues,
    isCalendarOpened,
    isPassengersOpened,
    isRoundTrip,
    layoverValue,
    maxPassengersCount,
    onClearReturnDate,
    onSearchHandler,
    openCalendar,
    openedPopup,
    openPassengers,
  } = useTrainSearchForm()
  const isTablet = useMediaQuery(mediaQueries.tablet)
  const isMobile = useMediaQuery(mediaQueries.mobile)
  const isOpenLayovers = useAppSelector(selectIsOpenLayovers)

  const [, { isLoading }] = usePostSearchMutation({ fixedCacheKey: fixedPostSearchCacheKey })

  useEffect(() => {
    const header = document.getElementById(headerId)
    if (header) {
      header.style.zIndex = openedPopup ? '0' : '3'
    }
    /*if (main) {
      main.style.zIndex = openedPopup ? 'unset' : '1'
    }*/
  }, [openedPopup])

  const onFinish = useCallback(
    (values: SearchFormValuesType) => {
      onSearchHandler(values)
      document.location.search && dispatch(setQueryParams(document.location.search))
      void router.push(routes.checkoutTimetable)
    },
    [router, onSearchHandler]
  )
  return (
    <>
      <Form
        form={form}
        initialValues={initialValues}
        name={searchFormId}
        onFinish={onFinish}
        onFinishFailed={onErrorHandler}
        style={{ width: '100%', zIndex: 4 }}
        validateMessages={undefined}
      >
        <StationsFields
          arrivalStationValue={arrivalStationValue}
          departureStationValue={departureStationValue}
          disableClear={isMobile}
          layoverValue={layoverValue}
          setFieldValue={form.setFieldValue}
        />
        <DateInput
          datePickerProps={{
            disabled: [false, isOpenLayovers || !!layoverValue],
            popupStyle: popupPositionsMainPage(isTablet, !!layoverValue),
          }}
          close={closePopup}
          isOpened={isCalendarOpened}
          onClearReturnDate={onClearReturnDate}
          open={openCalendar}
          position="right"
        />
        <PassengersLabel
          adultsCount={adults || initialValues[SearchFormKeys.adults]}
          childrenAge={childrenAge}
          childrenCount={children}
          close={closePopup}
          isOpened={isPassengersOpened}
          maxPassengersCount={maxPassengersCount}
          open={openPassengers}
        />
        <RecommendedRoutes
          arrivalStation={arrivalStationValue}
          departureDate={!isRoundTrip && date ? date[0] : undefined}
          departureStation={departureStationValue}
          selectedLayover={layoverValue}
          setStation={form.setFieldValue}
          submitForm={form.submit}
        />
        <SubmitButton isUploading={isLoading} />
      </Form>
      {openedPopup && <ShadowMask />}
    </>
  )
}

export default memo(SearchForm)
