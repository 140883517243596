import type { Dayjs } from 'dayjs'
import type { FC } from 'react'
import { memo } from 'react'

import dateFormats from '@constants/dates/dateFormats'

type Props = {
  departureDate?: Dayjs
}
const DepartureDate: FC<Props> = ({ departureDate }) => {
  return departureDate ? departureDate.format(dateFormats['01 Jan 2023']) : ''
}

export default memo(DepartureDate)
