import type { FC } from 'react'
import styled from 'styled-components'

import type { ExtendedFastestRouteType } from '@redux/features/recommendedRoutes/types/fastestRoutes'
import { selectWithLayover } from '@redux/features/search/search.selectors'
import { useAppSelector } from '@redux/hooks'

import {
  formHeightDesktop,
  searchFormSizes,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'
import type { PropsFastestTrains } from '@components/mainPage/recommendedRoutes/components/fastestTrains/FastestTrains'
import Line from '@components/mainPage/recommendedRoutes/components/fastestTrains/components/Line/Line'
import Header from '@components/mainPage/recommendedRoutes/components/fastestTrains/components/header/Header'
import { ContainerStyled } from '@components/mainPage/recommendedRoutes/styles/commonStyles'

const ContentFastestTrains: FC<PropsFastestTrains & { routesWithTimeLine: ExtendedFastestRouteType[] }> = ({
  departureStation,
  routesWithTimeLine,
  setStation,
  widthPopup,
}) => {
  const withLayover = useAppSelector(selectWithLayover)

  return (
    <Container $isLayover={withLayover} width={widthPopup}>
      <Header departureStation={departureStation?.label || ''} />
      <ListStyles>
        {routesWithTimeLine.map(route => (
          <Line key={route.station.id} route={route} setStation={setStation} />
        ))}
      </ListStyles>
    </Container>
  )
}

const Container = styled.div<{ $isLayover: boolean; width: number }>`
  width: ${({ width }) => width}px;
  min-height: ${p => formHeightDesktop + (p.$isLayover ? searchFormSizes.layoversHeightDesktop : 0)}px;

  ${ContainerStyled}

  ${p => p.theme.mediaQueries.mobileTablet} {
    height: 480px;
  }
`

export const ListStyles = styled.div`
  margin: 0 31px;
  display: grid;
  grid-gap: 2px;

  ${p => p.theme.mediaQueries.desktop} {
    margin: 0 7px;
    display: flex;
    flex-direction: column;
  }
`

export default ContentFastestTrains
