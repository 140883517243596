import type {
  ExtendedFastestRouteType,
  FastestRoutesListType,
} from '@redux/features/recommendedRoutes/types/fastestRoutes'
import type { LayoverPartRouteType } from '@redux/features/recommendedRoutes/types/layover'
import type { RecommendedRoutesType, TravelTimeType } from '@redux/features/recommendedRoutes/types/recommendedRoutes'

export const getTimeLineWithRoute = (popularRoutes: FastestRoutesListType | undefined): ExtendedFastestRouteType[] => {
  if (!popularRoutes) return []
  const popularRoutesArray = Object.values(popularRoutes)
  const maxTime = getMaxTime(popularRoutesArray)

  return popularRoutesArray.map(route => ({
    ...route,
    lineLength: (route.travel_time.seconds / maxTime) * 100,
  }))
}

export const getMaxTime = (routes: LayoverPartRouteType[] | RecommendedRoutesType[]): number => {
  const times = routes.map(route => route.travel_time.seconds)
  return Math.max(...times)
}

export const unpackTravelTime = (travelTime: TravelTimeType) => {
  const day = travelTime.duration?.d ? `${travelTime.duration.d} d` : ''
  const hours = travelTime.duration?.h ? `${travelTime.duration.h} h` : ''
  const minutes = travelTime.duration?.i ? `${travelTime.duration.i} m` : ''

  return `${day} ${hours} ${minutes}`
}
