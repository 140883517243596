import { Popover } from 'antd/es'
import type { FC, ReactNode } from 'react'

import { selectWithLayover } from '@redux/features/search/search.selectors'
import { useAppSelector } from '@redux/hooks'

import { getAlign } from '@components/mainPage/recommendedRoutes/components/popup/utils/getAlign'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import useScrollControl from '@hooks/useScrollControl/useScrollControl'
import { colorsList } from '@themes/colors/colorsList'
import { mediaQueries } from '@themes/mediaQueries'

type Props = {
  content: ReactNode
  isLayovers?: boolean
  isOpen: boolean
}

const Popup: FC<Props> = ({ content, isOpen }) => {
  const isMobile = useMediaQuery(mediaQueries.mobile)
  const withLayover = useAppSelector(selectWithLayover)

  useScrollControl(isMobile && isOpen)

  return (
    <Popover
      overlayInnerStyle={{
        background: isMobile ? colorsList.white : 'none',
        borderRadius: 0,
        boxShadow: 'none',
        padding: 0,
      }}
      align={getAlign(withLayover, isMobile)}
      arrow={false}
      content={content}
      open={isOpen}
      placement="rightBottom"
      zIndex={isMobile ? undefined : 1}
    />
  )
}

export default Popup
