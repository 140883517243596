import type { ExtendedLayoverType, RouteLayoverType } from '@redux/features/recommendedRoutes/types/layover'
import { nanoid } from '@reduxjs/toolkit'

import { getMaxTime } from '@components/mainPage/recommendedRoutes/components/fastestTrains/utils/routeProcessing'

type CreateLayoverWithTimelinesArgType = {
  isDesktop: boolean
  routeLayover: RouteLayoverType
}

export const createLayoverWithTimelines = ({
  isDesktop,
  routeLayover,
}: CreateLayoverWithTimelinesArgType): ExtendedLayoverType[] => {
  const route = Object.values(routeLayover)
  const minWidth = isDesktop ? 65 : 40
  let divider = 0

  if (isDesktop) {
    divider = getMaxTime(route)
  } else {
    divider = route.reduce((acc, route) => acc + route.travel_time.seconds, 0)
  }

  return route.map(route => {
    return {
      ...route,
      key: nanoid(),
      lineLength: Math.max((route.travel_time.seconds / divider) * 100, minWidth),
    } as ExtendedLayoverType
  })
}
