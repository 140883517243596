import type { FC } from 'react'
import { useMemo } from 'react'
import styled from 'styled-components'

import type { ExtendedLayoverType } from '@redux/features/recommendedRoutes/types/layover'

import { unpackTravelTime } from '@components/mainPage/recommendedRoutes/components/fastestTrains/utils/routeProcessing'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { UilClock } from '@iconscout/react-unicons'
import { ellipsisText } from '@styles/typography/textStyles'
import { mediaQueries } from '@themes/mediaQueries'
import Text from '@typography/Text'
import Title from '@typography/Title'

type Props = {
  route: ExtendedLayoverType
}
const PartLine: FC<Props> = ({ route }) => {
  const travelTime = useMemo(() => unpackTravelTime(route.travel_time), [route.travel_time])
  const isDesktop = useMediaQuery(mediaQueries.desktop)

  const levelStation = isDesktop ? 7 : 6

  return (
    <Content width={route.lineLength}>
      <Header>
        <Station level={levelStation}>{route.stations.departure.name}</Station>
        <Time size={isDesktop ? 's' : 'm'}>
          <UilClock />
          {travelTime}
        </Time>
        <Station level={levelStation}>{route.stations.arrival.name}</Station>
      </Header>
      <Line />
    </Content>
  )
}

const Content = styled.div<{ width: number }>`
  width: ${p => p.width}%;
  margin: 0 17px;

  ${p => p.theme.mediaQueries.desktop} {
    margin: 0;
  }
`

export const Line = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 8px;
`

export const Station = styled(Title)`
  ${ellipsisText};
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  white-space: nowrap;

  ${p => p.theme.mediaQueries.desktop} {
    margin-bottom: 5px;
  }
`

export const Time = styled(Text)`
  display: flex;
  align-items: center;
  margin: 0 20px;

  svg {
    margin: 0 5px;
  }

  ${p => p.theme.mediaQueries.desktop} {
    svg {
      width: 20px;
    }
    margin: 0 10px;
  }
`

export default PartLine
