import type { SwitchProps } from 'antd/es'
import { type FC, memo } from 'react'

import { SwitcherWrapper } from '@base/Toggler'

type Props = {
  labels: [string, string]
} & SwitchProps

const Toggler: FC<Props> = ({ labels, ...props }) => {
  return <SwitcherWrapper checkedChildren={labels[0]} unCheckedChildren={labels[1]} {...props} />
}

export default memo(Toggler)
