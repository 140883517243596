import { css } from 'styled-components'

export const ContainerStyled = css`
  display: flex;
  flex-direction: column;
  max-width: 808px;
  padding: 36px 0;
  border-radius: ${p => p.theme.borderRadius.default};
  background: ${p => p.theme.colors.backgroundContainer};
  box-shadow: ${p => p.theme.shadows.big};

  ${p => p.theme.mediaQueries.desktop} {
    padding: 24px 0;
    box-shadow: ${p => p.theme.shadows.small};
  }

  ${p => p.theme.mediaQueries.mobile} {
    min-height: auto;
  }
`
