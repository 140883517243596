import type { FC } from 'react'
import { memo, useMemo } from 'react'
import styled from 'styled-components'

import type { ExtendedFastestRouteType } from '@redux/features/recommendedRoutes/types/fastestRoutes'
import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'

import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import { unpackTravelTime } from '@components/mainPage/recommendedRoutes/components/fastestTrains/utils/routeProcessing'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { UilClock } from '@iconscout/react-unicons'
import { mediaQueries } from '@themes/mediaQueries'
import Text from '@typography/Text'
import Title from '@typography/Title'

type Props = {
  route: ExtendedFastestRouteType
  setStation: (name: string, value?: SearchStationsOptionType) => void
}

const Line: FC<Props> = ({ route, setStation }) => {
  const { lineLength, station, travel_time } = route
  const travelTime = useMemo(() => unpackTravelTime(travel_time), [travel_time])
  const isDesktop = useMediaQuery(mediaQueries.desktop)

  const setArrivalStation = () => {
    const { country_code, id, name, uuid } = station
    setStation(SearchFormKeys.arrival, {
      country_code,
      id,
      label: name,
      value: uuid,
    })
  }

  return (
    <Container onClick={setArrivalStation} width={lineLength}>
      <Header>
        <Title level={isDesktop ? 7 : 6}>{station.name}</Title>
        <Time size="m">
          <UilClock />
          {travelTime}
        </Time>
      </Header>
      <StyledLine />
    </Container>
  )
}

const Container = styled.div<{ width: number }>`
  height: 75px;
  width: ${p => p.width}%;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 17px;
  cursor: pointer;

  &:hover {
    border-radius: ${p => p.theme.borderRadius.default};
    box-shadow: ${p => p.theme.shadows.big};
    border: 1px solid ${p => p.theme.colors.timeLineActive};
  }

  ${p => p.theme.mediaQueries.desktop} {
    padding: 10px 17px;
    height: 65px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  white-space: nowrap;

  ${p => p.theme.mediaQueries.desktop} {
    margin-bottom: 10px;
  }
`

const Time = styled(Text)`
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.greyCarbon};

  svg {
    margin: 0 10px;
    fill: ${p => p.theme.colors.fontSecondary};

    ${p => p.theme.mediaQueries.desktop} {
      width: 20px;
    }
  }
`

const StyledLine = styled.div`
  height: 3px;
  border-radius: 8px;
  background: ${p => p.theme.colors.timeLine};
  width: 100%;
`

export default memo(Line)
