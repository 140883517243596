import type { FC } from 'react'
import { memo, useMemo } from 'react'

import { useGetFastestRoutesQuery } from '@redux/features/recommendedRoutes/recommendedRoutes.api'
import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'
import { skipToken } from '@reduxjs/toolkit/query'

import ContentFastestTrains from '@components/mainPage/recommendedRoutes/components/fastestTrains/components/contentFastestTrains/ContentFastestTrains'
import { getTimeLineWithRoute } from '@components/mainPage/recommendedRoutes/components/fastestTrains/utils/routeProcessing'
import Popup from '@components/mainPage/recommendedRoutes/components/popup/Popup'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'

export type PropsFastestTrains = {
  allStationsAreFull: boolean
  departureStation?: SearchStationsOptionType
  setStation: (name: string, value?: SearchStationsOptionType) => void
  widthPopup: number
}

const FastestTrains: FC<PropsFastestTrains> = props => {
  const { allStationsAreFull, departureStation } = props
  const isMobile = useMediaQuery(mediaQueries.mobile)
  const { data: fastestRoutes } = useGetFastestRoutesQuery(
    departureStation?.value ? { station: departureStation.value } : skipToken
  )

  const routesWithTimeLine = useMemo(() => getTimeLineWithRoute(fastestRoutes), [fastestRoutes])

  return (
    <Popup
      content={<ContentFastestTrains {...props} routesWithTimeLine={routesWithTimeLine} />}
      isOpen={!isMobile && !allStationsAreFull && !!departureStation && !!routesWithTimeLine.length}
    />
  )
}
export default memo(FastestTrains)
