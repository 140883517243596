import Head from 'next/head'
import type { FC } from 'react'

import type { HomePageEntityType } from '@redux/features/homePage/types/blocksHomePage'

import isProd from '@constants/env/isProd'
import { type DefaultServerSidePropsType } from '@utils/serverSideProps/getDefaultServerSideProps'

export type MetaDataProps = DefaultServerSidePropsType & {
  metaTags: HomePageEntityType['field_metatags'] | null
  noIndex?: boolean
  schemaOrgScriptData?: string
}

const MetaData: FC<MetaDataProps> = ({ canonicalUrl, metaTags, noIndex, schemaOrgScriptData }) => {
  return (
    <Head>
      <title>{metaTags?.title}</title>
      {metaTags &&
        Object.entries(metaTags).map(
          ([name, content]) => name !== 'title' && <meta content={content} key={name} name={name} />
        )}
      {(noIndex || !isProd) && <meta content="noindex, nofollow" name="robots" />}
      <link href={canonicalUrl} rel="canonical" />
      {schemaOrgScriptData && (
        <script
          dangerouslySetInnerHTML={{
            __html: schemaOrgScriptData,
          }}
          type="application/ld+json"
        />
      )}
    </Head>
  )
}

export default MetaData
