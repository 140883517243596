import {
  searchFormSizes,
  topPartHeightDesktop,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'

export const getAlign = (isLayover: boolean, isMobile: boolean) => {
  if (isMobile) {
    return {
      offset: [0, 0],
      overflow: { shiftX: 0, shiftY: 0 },
    }
  }
  return {
    offset: [
      searchFormSizes.formWidthDesktop + 24,
      -topPartHeightDesktop -
        (isLayover ? searchFormSizes.layoversHeightDesktop : 0) -
        searchFormSizes.fieldHeightDesktop,
    ],
  }
}
