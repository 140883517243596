import type { Dayjs } from 'dayjs'
import type { FC } from 'react'
import { useEffect } from 'react'

import { useGetLayoversQuery } from '@redux/features/recommendedRoutes/recommendedRoutes.api'
import { selectIsOpenLayovers } from '@redux/features/recommendedRoutes/recommendedRoutes.selectors'
import { setIsOpenLayovers } from '@redux/features/recommendedRoutes/recommendedRoutes.slice'
import { selectRoundTrip } from '@redux/features/search/search.selectors'
import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { skipToken } from '@reduxjs/toolkit/query'

import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import Popup from '@components/mainPage/recommendedRoutes/components/popup/Popup'
import LayoversContent from '@components/mainPage/recommendedRoutes/components/recommendedLayovers/components/layoversContent/LayoversContent'

import { WrapperContent } from './styles'

export type PropsLayovers = {
  allStationsAreFull: boolean
  arrivalStation?: SearchStationsOptionType
  departureDate?: Dayjs
  departureStation?: SearchStationsOptionType
  selectedLayover?: SearchStationsOptionType
  setStation: (name: string, value?: SearchStationsOptionType) => void
  submitForm: () => void
  widthPopup: number
}
export const RecommendedLayovers: FC<PropsLayovers> = props => {
  const { allStationsAreFull, arrivalStation, departureStation } = props
  const isOpenPopup = useAppSelector(selectIsOpenLayovers)
  const isRoundTrip = useAppSelector(selectRoundTrip)
  const dispatch = useAppDispatch()

  const { data, status } = useGetLayoversQuery(
    departureStation?.value && arrivalStation?.value
      ? {
          stationOne: departureStation.value,
          stationTwo: arrivalStation.value,
        }
      : skipToken
  )

  useEffect(() => {
    dispatch(setIsOpenLayovers(!isRoundTrip && allStationsAreFull && !!data && status === 'fulfilled'))
  }, [status, allStationsAreFull, data, dispatch, isRoundTrip])

  if (!data || isRoundTrip) return null

  return (
    <Popup
      content={
        <WrapperContent name={SearchFormKeys.layover} rules={[{ required: !isRoundTrip }]} validateStatus={undefined}>
          <LayoversContent {...props} layoversList={data} />
        </WrapperContent>
      }
      isOpen={isOpenPopup}
    />
  )
}
