import type { Dayjs } from 'dayjs'
import type { FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'

import HeaderInfoRouteMobile from '@components/headerInfoRouteMobile/HeaderInfoRouteMobile'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'
import Title from '@typography/Title'

import DepartureDate from './departureDate/DepartureDate'
import RouteStation from './routeStation/RouteStation'

type Props = {
  arrivalStation?: SearchStationsOptionType
  departureDate?: Dayjs
  departureStation?: SearchStationsOptionType
}
const Header: FC<Props> = ({ arrivalStation, departureDate, departureStation }) => {
  const { t } = useTranslation('Recommended routes')
  const label = `${t('recommendedLayovers')}:`
  const isDesktop = useMediaQuery(mediaQueries.desktop)
  const isMobile = useMediaQuery(mediaQueries.mobile)

  const DepartureDateHoc = () => <DepartureDate departureDate={departureDate} />

  const RouteStationHoc = (props: { stationType: 'arrival_station' | 'departure_station' }) => (
    <RouteStation arrivalStation={arrivalStation} departureStation={departureStation} stationType={props.stationType} />
  )

  if (isMobile) {
    return <HeaderInfoRouteMobile DepartureDate={DepartureDateHoc} label={label} RouteStation={RouteStationHoc} />
  }

  return (
    <Container>
      <Title level={isDesktop ? 4 : 2}>{label}</Title>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 48px;

  ${p => p.theme.mediaQueries.desktop} {
    margin: 0 24px 25px 24px;
  }
`

export default memo(Header)
