import type { FC } from 'react'
import { memo } from 'react'

import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'

type Props = {
  arrivalStation?: SearchStationsOptionType
  departureStation?: SearchStationsOptionType
  stationType: 'arrival_station' | 'departure_station'
}

const RouteStation: FC<Props> = ({ arrivalStation, departureStation, stationType }) => {
  return stationType === 'arrival_station' ? arrivalStation?.label : departureStation?.label
}

export default memo(RouteStation)
