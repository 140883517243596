import { api } from '@redux/api/api'
import type { FastestRoutesListType, GetFastestRoutesType } from '@redux/features/recommendedRoutes/types/fastestRoutes'
import type { GetLayoverType, LayoversListType } from '@redux/features/recommendedRoutes/types/layover'

import { urlVersionPrefix } from '@constants/api/api'

export const recommendedRoutesApi = api.injectEndpoints({
  endpoints: build => ({
    getFastestRoutes: build.query<FastestRoutesListType, GetFastestRoutesType>({
      query: ({ limit = 5, station }) => {
        return {
          url: urlVersionPrefix + `station/${station}/fastest-routes/${limit}`,
        }
      },
    }),

    getLayovers: build.query<LayoversListType, GetLayoverType>({
      query: ({ stationOne, stationTwo }) => {
        return {
          url: urlVersionPrefix + `layover/${stationOne}/${stationTwo}`,
        }
      },
    }),

    /*getRouteMessage: build.query<RouteMessageType, GetRouteMessageType>({
      query: ({ arrival, departure }) => {
        return {
          url: urlVersionPrefix + `route-message/${departure}/${arrival}`,
        }
      },
    }),*/
  }),
})

export const {
  endpoints: recommendedRoutesEndpoints,
  useGetFastestRoutesQuery,
  useGetLayoversQuery,
} = recommendedRoutesApi
