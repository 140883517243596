export enum BlocksKeys {
  ABOUT = 'about',
  ABOUT_WITH_VIDEO = 'about_with_video',
  ADVANTAGES_TWO = 'advantages_2',
  AWARDS = 'awards',
  BLOCK_WITH_MENU = 'block_with_menu',
  CARRIERS = 'carriers',
  NODE_CUSTOM_CONTENT_ID = 'node_custom_content_id',
  PAYMENT_METHODS = 'payment_methods',
  POPULAR_QUESTION = 'popular_questions',
  PROMO_BLOCK = 'promo_block',
  REVIEW_BLOCK = 'review_block',
}
