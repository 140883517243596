import type { FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'
import Title from '@typography/Title'

type Props = {
  departureStation: string
}

const Header: FC<Props> = ({ departureStation }) => {
  const { t } = useTranslation('Recommended routes')
  const isDesktop = useMediaQuery(mediaQueries.desktop)

  return (
    <Container>
      <Title level={isDesktop ? 4 : 2}>
        {t('fastestTrainsFrom')} {departureStation}
      </Title>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 48px;

  ${p => p.theme.mediaQueries.desktop} {
    margin: 0 24px 25px 24px;
  }
`

export default memo(Header)
