import Form from 'antd/es/form'
import styled from 'styled-components'

export const WrapperContent = styled(Form.Item)`
  .ant-form-item-explain-error {
    display: none;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`
