import type { FC } from 'react'
import { memo, useMemo } from 'react'
import styled from 'styled-components'

import type { RouteLayoverType } from '@redux/features/recommendedRoutes/types/layover'
import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'

import PartLine, {
  Line,
  Station,
  Time,
} from '@components/mainPage/recommendedRoutes/components/recommendedLayovers/components/doubleLine/partLine/PartLine'
import { createLayoverWithTimelines } from '@components/mainPage/recommendedRoutes/components/recommendedLayovers/utils/routeProcessing'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'

type Props = {
  routeLayover: RouteLayoverType
  selectedLayover?: SearchStationsOptionType
  setStation: (value: SearchStationsOptionType) => void
}

const DoubleLine: FC<Props> = ({ routeLayover, selectedLayover, setStation }) => {
  const isDesktop = useMediaQuery(mediaQueries.desktop)

  const layover = routeLayover.layover_first_route.stations.arrival

  const layoverWithTimeLine = useMemo(
    () => createLayoverWithTimelines({ isDesktop, routeLayover }),
    [routeLayover, isDesktop]
  )

  const setLayover = () => {
    setStation({
      country_code: layover.country_code,
      id: layover.id,
      label: layover.name,
      value: layover.uuid,
    })
  }

  return (
    <ContainerDoubleLine $isActive={selectedLayover?.value === layover.uuid} onClick={setLayover}>
      {layoverWithTimeLine.map(route => (
        <PartLine key={route.key} route={route} />
      ))}
    </ContainerDoubleLine>
  )
}

export const ContainerDoubleLine = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  height: 75px;
  width: 100%;
  padding: 17px 0;
  cursor: pointer;
  border-radius: ${p => p.theme.borderRadius.default};
  border: ${p => (p.$isActive ? `1px solid ${p.theme.colors.timeLineActive}` : '1px solid rgba(0, 0, 0, 0)')};

  ${Station} {
    color: ${p => (p.$isActive ? p.theme.colors.fontMain : p.theme.colors.greyCarbon)};
  }

  ${Line} {
    background: ${p => (p.$isActive ? p.theme.colors.timeLineActive : p.theme.colors.timeLineDouble)};
  }

  ${Time} {
    color: ${p => (p.$isActive ? p.theme.colors.fontMain : p.theme.colors.greyCarbon)};
    svg {
      fill: ${p => (p.$isActive ? p.theme.colors.fontMain : p.theme.colors.greyCarbon)};
    }
  }

  &:hover {
    border: 1px solid ${p => p.theme.colors.timeLineActive};

    ${Line} {
      background: ${p => p.theme.colors.timeLineActive};
    }
  }

  ${p => p.theme.mediaQueries.desktop} {
    height: 140px;
    min-height: 140px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px 16px;
    border: ${p => (p.$isActive ? `1px solid ${p.theme.colors.blueLite}` : `1px solid ${p.theme.colors.greySilver}`)};
  }

  ${p => p.theme.mediaQueries.mobile} {
    background: ${p => p.theme.colors.backgroundContainer};
    border: 1px solid ${p => p.theme.colors.blueLite};
  }
`

export default memo(DoubleLine)
