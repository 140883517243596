import type { FC } from 'react'
import styled from 'styled-components'

import { setIsOpenLayovers } from '@redux/features/recommendedRoutes/recommendedRoutes.slice'
import type { LayoversListType } from '@redux/features/recommendedRoutes/types/layover'
import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'
import { useAppDispatch } from '@redux/hooks'

import { layoversListClassName } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/classNames'
import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import DoubleLine from '@components/mainPage/recommendedRoutes/components/recommendedLayovers/components/doubleLine/DoubleLine'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'

type Props = {
  list: LayoversListType
  selectedLayover?: SearchStationsOptionType
  setStation: (name: string, value?: SearchStationsOptionType) => void
  submitForm: () => void
}

const LayoverList: FC<Props> = ({ list, selectedLayover, setStation, submitForm }) => {
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery(mediaQueries.mobile)

  const handlerSetStation = (value: SearchStationsOptionType) => {
    dispatch(setIsOpenLayovers(false))
    setStation(SearchFormKeys.layover, value)
    isMobile && submitForm()
  }

  return (
    <Container className={layoversListClassName}>
      {Object.entries(list).map(([key, routeLayover]) => {
        return (
          <DoubleLine
            key={key}
            routeLayover={routeLayover}
            selectedLayover={selectedLayover}
            setStation={handlerSetStation}
          />
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  margin: 0 31px;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }

  ${p => p.theme.mediaQueries.desktop} {
    margin: 0 24px;

    & > *:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  ${p => p.theme.mediaQueries.mobile} {
    height: 100%;
    margin: 0;
    padding: 17px 24px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: linear-gradient(rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 100%);
  }
`

export default LayoverList
