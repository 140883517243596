import type { Dayjs } from 'dayjs'
import type { FC } from 'react'
import { memo, useEffect, useState } from 'react'

import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'

import FastestTrains from '@components/mainPage/recommendedRoutes/components/fastestTrains/FastestTrains'
import RecommendedLayovers from '@components/mainPage/recommendedRoutes/components/recommendedLayovers'
import { getWidthPopup } from '@components/mainPage/recommendedRoutes/utils/getWidthPopup'

type Props = {
  arrivalStation?: SearchStationsOptionType
  departureDate?: Dayjs
  departureStation?: SearchStationsOptionType
  selectedLayover?: SearchStationsOptionType
  setStation: (name: string, value?: SearchStationsOptionType) => void
  submitForm: () => void
}

const RecommendedRoutes: FC<Props> = ({
  arrivalStation,
  departureDate,
  departureStation,
  selectedLayover,
  setStation,
  submitForm,
}) => {
  const [widthPopup, setWidthPopup] = useState(getWidthPopup())
  const allStationsAreFull = !!departureStation && !!arrivalStation

  const handleResize = () => {
    setWidthPopup(getWidthPopup())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <RecommendedLayovers
        allStationsAreFull={allStationsAreFull}
        arrivalStation={arrivalStation}
        departureDate={departureDate}
        departureStation={departureStation}
        selectedLayover={selectedLayover}
        setStation={setStation}
        submitForm={submitForm}
        widthPopup={widthPopup}
      />
      <FastestTrains
        allStationsAreFull={allStationsAreFull}
        departureStation={departureStation}
        setStation={setStation}
        widthPopup={widthPopup}
      />
    </>
  )
}

export default memo(RecommendedRoutes)
